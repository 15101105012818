
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import {
  getComponent,
  getConfigEnv,
  getISOStringWithLocalOffset,
  isNumberConvertable,
} from '@/utils/helpers';
import { DateTime } from 'luxon';
import gptModule from '@/store/modules/gptModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    Multiselect,
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestionV2'),
  },
})
export default class TasqLastActionDetails extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  @Prop({ type: Object, required: true }) lastAction?: any;

  // @Prop({ type: String, required: false, default: '' }) lastActionTitle?: any;

  @Prop({ type: Object, required: true }) lastActionCateogryData?: any;

  body = '';

  startDate = '';

  endDate = '';

  reason = '';

  newValue: any = '';

  get isLastActionIsPadLevel(){
    return this.lastAction.nodeID !== this.tasq.wellName
  }

  get isEditableAction(){
    return this.lastAction.id
  }

  category = '';

  subCategory = '';

  fixedCategory = '';

  cause = '';

  oldValue: any = '';

  title = '';

  formId = '';

  inputPrePostChanges: any = [];

  preInput: any = null;

  postInput: any = null;

  get lastActionsForm() {
    let forms: any = metaDataModule.formBuilderData;
    return forms.find((f) => {
      return f.id === this.formId;
    });
  }

  isSavingData = false;

  created() {
    this.startDate =
      this.lastAction.type.toLowerCase() === 'waiting on' &&
      this.lastAction.start_date
        ? this.lastAction.start_date
        : this.lastAction.date;
    // this.endDate = this.lastAction.date;

    console.log(this.lastAction.body);
    this.body = this.lastAction.body;
    this.reason = this.lastAction.summary;
    this.title = this.lastAction.title;
    this.endDate = this.lastAction.endDate;
    this.formId = this.lastAction.form_id;
    this.fixedCategory = this.lastAction.fixedCategory;
    this.cause = this.lastAction.cause;
    this.category = this.lastAction.category;
    this.subCategory = this.lastAction.subCategory;

    if (this.lastAction.pre_input && Object.keys(this.lastAction.pre_input)) {
      this.preInput = this.lastAction.pre_input;
    }

    if (this.lastAction.post_input && Object.keys(this.lastAction.post_input)) {
      this.postInput = this.lastAction.post_input;
      this.inputPrePostChanges = Object.keys(this.postInput).map((i) => {
        if (i !== 'date') {
          return `Setpoint type :  ${i} \nFrom  ${
            isNumberConvertable(this.postInput[i])
              ? this.postInput[i].toFixed(1)
              : this.postInput[i]
          } to ${
            isNumberConvertable(this.preInput[i])
              ? this.preInput[i].toFixed(1)
              : this.preInput[i]
          } `;
        }
      });
    }

    // if(this.lastAction.old_value){
    //   this.oldValue = `From: ${this.lastAction.old_value}`
    // }

    // if(this.lastAction.new_value){
    //   this.oldValue = `To: ${this.lastAction.new_value}`
    // }
  }

  mounted() {
    this.$nextTick(() => {
      this.resizeTextarea('textarea1'); // Resize on component mount for textarea1
      this.resizeTextarea('textarea2');
    });
  }

  get disabledDates() {
    return [
      (date) => {
        const startDate = new Date(this.startDate);
        const endDate = DateTime.fromISO(date.toISOString());
        startDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return date <= startDate;
      },
    ];
  }

  get isValidForm() {
    if (!this.startDate) {
      return false;
    }
    if (!this.reason) {
      return false;
    }

    if (!this.lastAction.id) {
      return false;
    }

    return true;
  }

  get operatorList() {
    return [
      { operatorID: 8, operatorName: 'petronas', appName: 'petronascanada' },
      { operatorID: 7, operatorName: 'taprock', appName: 'taprock' },
      { operatorID: 6, operatorName: 'pdce', appName: 'pdc' },
      { operatorID: 2, operatorName: 'SWN', appName: 'swn' },
      { operatorID: 1, operatorName: 'Extraction', appName: 'civitas' },
      { operatorID: 9, operatorName: 'demo', appName: 'demo' },
      { operatorID: 5, operatorName: 'caerus', appName: 'caerus' },
    ];
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  get loading() {
    return gptModule.isLoadingActions;
  }

  get getCategory() {
    const data = this.lastActionCateogryData;
    if (data[this.lastAction.title] && data[this.lastAction.title].length) {
      return data[this.lastAction.title].map((item) => {
        return item['category'];
      });
    }
    // Check if the type exists in the data
    return [];
  }

  get getSubCategories() {
    const data = this.lastActionCateogryData;
    // Check if the type exists in the data
    if (data[this.lastAction.title]) {
      // If a specific category is requested
      if (this.category) {
        // Find the category within the type
        const categoryData = data[this.lastAction.title].find(
          (item) => item.category === this.category
        );
        if (categoryData) {
          return categoryData['subcategory'];
        }
      }
    }

    return [];
  }

  get getFixedCategories() {
    const data = this.lastActionCateogryData;
    // Check if the type exists in the data
    if (data[this.lastAction.title]) {
      // If a specific category is requested
      if (this.category) {
        // Find the category within the type
        const categoryData = data[this.lastAction.title].find(
          (item) => item.category === this.category
        );
        if (categoryData) {
          return categoryData['fixedcategory'];
        }
      }
    }

    return [];
  }

  get getCauseList() {
    const data = this.lastActionCateogryData;
    // Check if the type exists in the data
    if (data[this.lastAction.title]) {
      // If a specific category is requested
      if (this.category) {
        // Find the category within the type
        const categoryData = data[this.lastAction.title].find(
          (item) => item.category === this.category
        );
        if (categoryData) {
          return categoryData['cause'];
        }
      }
    }

    return [];
  }

  async updateAiComment() {
    this.$nextTick(() => {
      const payload = {
        id: this.lastAction.id,
        type: this.lastAction.title,
        summary: this.reason,
        body: this.body,
        category: this.category,
        subCategory: this.subCategory,
        fixedCategory: this.fixedCategory,
        cause: this.cause,
        startDate: getISOStringWithLocalOffset(this.startDate),
        endDate: getISOStringWithLocalOffset(this.endDate),
      };
      this.$emit('update-last-action', payload);
    });
  }

  async deleteAiComment() {
    this.$nextTick(() => {
      const payload = {
        id: this.lastAction.id,
      };
      this.$emit('delete-last-action', payload);
    });
  }

  updateListText(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  resizeTextarea(refName) {
    const textarea: any = this.$refs[refName];
    if (textarea) {
      textarea.style.height = 'auto'; // Reset the height first
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }


  unCheckTodoItem(){
    this.updateTodoItem(false)
  }

  updateTodoItem(checked=false){

    const payload = {
      ...this.lastAction,
      text: this.body,
      checked: checked,
    }
    this.$emit('update-todo-item',payload);
  }

  @Watch('lastAction.title')
  onTitleUpdate() {
    this.title = this.lastAction.title;
  }
}
